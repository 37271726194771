import React, { useMemo } from "react";
import { Col } from "react-bootstrap";
import Icofont from "../../../components/IcoFont";
import _ from "lodash";
import moment from "moment";
import classnames from "classnames";
import { useSpring, animated } from "react-spring";
import { useTrans } from "../../../hooks";

const TrackOrderStatusOutForDelivery = ({ order }) => {
  const transMsg = useTrans();
  const pluse = useSpring({
    to: async (next, cancel) => {
      while (1) {
        await next({ opacity: 1 });
        await next({ opacity: 0.3 });
        if (!orderNext) cancel();
      }
    },
    from: { opacity: 0.5 },
  });

  const orderDone = useMemo(() => {
    return (
      order.state === "PICKED_UP" ||
      order.state === "DELIVERD" ||
      order.state === "CANCELLED"
    );
  }, [order]);

  const orderNow = useMemo(() => {
    return order.state === "OUT_FOR_DELIVERY";
  }, [order]);

  const orderNext = useMemo(() => {
    return order.state === "READY_FOR_DELIVERY" || order.state === "APPROVED";
  }, [order]);

  const preparationTime = useMemo(() => {
    const timePass = new Date().getTime() - new Date(order.approvedAt).getTime();
    const timePassInMin = _.toInteger(timePass / 60000);
    return order.preparationTime - timePassInMin;
  }, [order.preparationTime, order.approvedAt]);

  return (
    <>
      <Col xl={3} lg={4} md={6} sm={12} className="py-2">
        <Icofont
          icon="food-basket"
          className={classnames("icofont-3x", {
            "text-primary": orderNext,
            "text-info": orderDone,
            "text-warning": orderNow,
            "text-muted": !orderNow && !orderDone && !orderNext,
          })}
        />
        <div className="mt-1 font-weight-bold text-dark mb-0">
          {transMsg("orderOutfor")} {_.capitalize(order.type)}
        </div>
        {(orderNow || orderDone) && (
          <div className="text-muted  font-weight-light">
            {moment(order.outForDeliveryAt).format("MMMM Do YYYY, h:mm A")}
          </div>
        )}

        {orderNext && order.state === "APPROVED" && (
          <div className="text-muted  font-weight-light">
            {transMsg("preparationTime")}{" "}
            <animated.span style={pluse} className="text-primary font-weight-bold">
              {preparationTime}
            </animated.span>{" "}
            {transMsg("minutes")}
          </div>
        )}

        <div
          className={classnames("mb-0", {
            "text-primary": orderNext,
            "text-info": orderDone,
            "text-warning ": orderNow,
          })}
        >
          {orderNow && <>{_.upperCase(transMsg("now"))} </>}
          {orderDone && <>{_.upperCase(transMsg("done"))} </>}
          {orderNext && <>{_.upperCase(transMsg("next"))} </>}
        </div>
      </Col>
    </>
  );
};

export default TrackOrderStatusOutForDelivery;
