import React, { useEffect, useState } from "react";

import Layout from "../../components/Layout";
import _ from "lodash";
import { useOrder } from "../../queries";
import { useRemoveSearchParams } from "../../hooks";
import { Container } from "react-bootstrap";
import OrderHeader from "./header";
import TrackOrderStatus from "./OrderStatus";
import OrderDetails from "./details";
import { useTrans } from "../../hooks";

const TrackOrder = ({ pageContext, location }) => {
  const { seoData, layoutProps } = pageContext;
  const { data, onExecuteQuery } = useOrder();
  const transMsg = useTrans();

  const [order, setOrder] = useState({});
  const searchParams = useRemoveSearchParams(location);

  useEffect(() => {
    if (_.isEmpty(searchParams)) return;
    const { token } = searchParams;
    if (_.isEmpty(token)) return;

    onExecuteQuery({ token });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (_.isEmpty(data)) return;

    setOrder({
      ...data.storefrontOrder,
    });
  }, [data]);

  return (
    <Layout seo={seoData} {...layoutProps}>
      <Container className="p-5">
        {_.isEmpty(order) && (
          <>
            <div className="row">
              <div className="col-12">{transMsg("rightUrlMessage")}</div>
            </div>
          </>
        )}

        {!_.isEmpty(order) && (
          <>
            <OrderHeader order={order} />
            <hr />
            <TrackOrderStatus order={order} />
            <hr className="pb-4" />
            <OrderDetails order={order} />
          </>
        )}
      </Container>
    </Layout>
  );
};

export default TrackOrder;
