import React, { useMemo } from "react";
import { Col } from "react-bootstrap";
import Icofont from "../../../components/IcoFont";
import moment from "moment";
import classnames from "classnames";
import { useTrans } from "../../../hooks";

const TrackOrderStatusCancelled = ({ order }) => {
  const transMsg = useTrans();
  const orderDone = useMemo(() => {
    return order.state === "CANCELLED";
  }, [order]);

  return (
    <>
      <Col xl={3} lg={4} md={6} sm={12} className="py-2">
        <Icofont
          icon="close-circled"
          className={classnames("icofont-3x", {
            "text-danger": orderDone,
          })}
        />
        <div className="mt-1 font-weight-bold text-dark mb-0">
          {transMsg("orderDecline")}
        </div>
        {orderDone && (
          <div className="text-muted  font-weight-light">
            {moment(order.canceledAt).format("MMMM Do YYYY, h:mm A")}
          </div>
        )}

        <div
          className={classnames("mb-0", {
            "text-danger": orderDone,
          })}
        >
          {orderDone && <>{order.cancelReason}</>}
        </div>
      </Col>
    </>
  );
};

export default TrackOrderStatusCancelled;
