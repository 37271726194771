import React, { useMemo } from "react";
import { Col } from "react-bootstrap";
import Icofont from "../../../components/IcoFont";
import moment from "moment";
import classnames from "classnames";
import { useTrans } from "../../../hooks";
import _ from "lodash";

const TrackOrderStatusDelivered = ({ order }) => {
  const transMsg = useTrans();

  const orderDone = useMemo(() => {
    return order.state === "DELIVERD";
  }, [order]);

  const orderNext = useMemo(() => {
    return order.state === "OUT_FOR_DELIVERY";
  }, [order]);

  return (
    <>
      <Col xl={3} lg={4} md={6} sm={12} className="py-2">
        <Icofont
          icon="delivery-time"
          className={classnames("icofont-3x", {
            "text-primary": orderNext,
            "text-info": orderDone,
            "text-muted": !orderDone && !orderNext,
          })}
        />
        <div className="mt-1 font-weight-bold text-dark mb-0">
          {transMsg("orderDelivered")}
        </div>
        {orderDone && (
          <div className="text-muted  font-weight-light">
            {moment(order.completedAt).format("MMMM Do YYYY, h:mm A")}
          </div>
        )}

        <div
          className={classnames("mb-0", {
            "text-primary": orderNext,
            "text-info": orderDone,
          })}
        >
          {orderDone && <>{_.upperCase(transMsg("done"))} </>}
          {orderNext && <>{_.upperCase(transMsg("next"))} </>}
        </div>
      </Col>
    </>
  );
};

export default TrackOrderStatusDelivered;
