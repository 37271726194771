import React, { useMemo } from "react";
import { Row } from "react-bootstrap";
import TrackOrderStatusReceived from "./received";
import TrackOrderStatusConfirmed from "./confirmed";
import TrackOrderStatusReady from "./ready";
import TrackOrderStatusPickedUp from "./pickedUp";
import TrackOrderStatusCancelled from "./cancelled";
import TrackOrderStatusDelivered from "./delivery";
import TrackOrderStatusOutForDelivery from "./outForDelivery";

const TrackOrderStatus = ({ order }) => {
  const orderPickUp = useMemo(() => {
    return order.type === "pickup";
  }, [order]);

  const orderCancelled = useMemo(() => {
    return order.state === "CANCELLED";
  }, [order]);

  return (
    <>
      <Row className="text-center">
        <TrackOrderStatusReceived order={order} />
        {!orderCancelled && (
          <>
            <TrackOrderStatusConfirmed order={order} />

            {orderPickUp ? (
              <>
                <TrackOrderStatusReady order={order} />
                <TrackOrderStatusPickedUp order={order} />
              </>
            ) : (
              <>
                <TrackOrderStatusOutForDelivery order={order} />
                <TrackOrderStatusDelivered order={order} />
              </>
            )}
          </>
        )}
        {orderCancelled && (
          <>
            <TrackOrderStatusCancelled order={order} />
          </>
        )}
      </Row>
    </>
  );
};

export default TrackOrderStatus;
