import React, { useEffect, useState, useContext } from "react";
import StrapiImage, { STRAPI_IMAGE_SIZES } from "../StrapiImage";
import _ from "lodash";
import { BreakpointContext } from "../../context";
import IcoFont from "../IcoFont";
import { useTrans } from "../../hooks";

const CartDropdownItem = ({
  title = "",
  price = "",
  choices = [],
  image,
  note = "",
} = {}) => {
  const transMsg = useTrans();
  const [groupedChoices, setGroupedChoices] = useState([]);
  const breakpoints = useContext(BreakpointContext);

  useEffect(() => {
    if (_.isEmpty(choices)) return;

    setGroupedChoices(
      _.groupBy(choices, (c) => {
        return c.modifier.name;
      }),
    );
  }, [choices]);
  return (
    <div className="row ">
      <div className="col-12">
        <ul className="list-inline ">
          {image && (
            <li className="list-inline-item align-top">
              <StrapiImage
                alt=""
                width={breakpoints.md ? 80 : 120}
                className="mr-3"
                src={image}
                size={STRAPI_IMAGE_SIZES.THUMBNAIL}
              />{" "}
            </li>
          )}
          <li className="list-inline-item">
            <div>
              {title}
              <ul className="list-unstyled">
                {_.map(groupedChoices, (v, k) => {
                  return (
                    <React.Fragment key={k}>
                      <li>
                        <IcoFont icon="rounded-right" />
                        {_.capitalize(k)}:
                      </li>
                      <ul className="list-unstyled">
                        {v.map((c, i) => {
                          return (
                            <React.Fragment key={i}>
                              <li>
                                <IcoFont
                                  className="ml-1"
                                  icon="rounded-right"
                                  style={{ color: "grey" }}
                                />
                                {c.title}
                              </li>
                            </React.Fragment>
                          );
                        })}
                      </ul>
                    </React.Fragment>
                  );
                })}
              </ul>
              {!_.isEmpty(note) && (
                <>
                  {""}
                  <small className="text-grey">
                    {" "}
                    {_.upperCase(transMsg("note"))}: {note}
                  </small>
                </>
              )}
            </div>
          </li>
          <li className="list-inline-item align-top float-right">{price}</li>
        </ul>
      </div>
    </div>
  );
};

export default CartDropdownItem;
