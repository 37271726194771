import React from "react";
import Icofont from "../../components/IcoFont";
import moment from "moment";
import { useTrans } from "../../hooks";

const OrderHeader = ({ order }) => {
  const transMsg = useTrans();

  return (
    <>
      <div className="row">
        <div className="col-12 text-center">
          <h6>
            {" "}
            {transMsg("order")} {order.displayOrderId}{" "}
          </h6>
        </div>
        <div className="col-12 text-center">
          <p className="text-gray mb-0">
            <Icofont icon="clock-time" />{" "}
            {moment(order.created_at).format("MMMM Do YYYY, h:mm:ss a")} |{" "}
            {order.lineitems.length} {transMsg("items")} | ${order.total}
          </p>
        </div>
      </div>
    </>
  );
};

export default OrderHeader;
